import React from 'react';
import Layout from '../../components/Layout';
import { StaticImage } from "gatsby-plugin-image"
import img1 from '../../assets/images/gallery/1.jpg'
import img2 from '../../assets/images/gallery/2.jpg'
import img3 from '../../assets/images/gallery/3.jpg'
import img4 from '../../assets/images/gallery/4.jpg'
import img5 from '../../assets/images/gallery/5.jpg'
import img6 from '../../assets/images/gallery/6.jpg'
import img7 from '../../assets/images/gallery/7.jpg'
import img8 from '../../assets/images/gallery/8.jpg'
import img9 from '../../assets/images/gallery/9.jpg'
import img10 from '../../assets/images/gallery/10.jpg'
import img11 from '../../assets/images/gallery/11.jpg'
import img12 from '../../assets/images/gallery/12.jpg'
import img13 from '../../assets/images/gallery/13.jpg'
import img14 from '../../assets/images/gallery/14.jpg'
import img0 from '../../assets/images/gallery/0.jpg'
import img16 from '../../assets/images/gallery/16.jpg'
import img17 from '../../assets/images/gallery/17.jpg'
import img18 from '../../assets/images/gallery/18.jpg'
import img19 from '../../assets/images/gallery/19.jpg'
import img20 from '../../assets/images/gallery/20.jpg'
import img21 from '../../assets/images/gallery/21.jpg'
import { LightgalleryItem } from "react-lightgallery";

const IndexPage = () => {
  const [ visible, setVisible ] = React.useState(true);

  return (
    <>
      <Layout page="gallery">
        <article id="main">
          <header>
            <h2>Our wines</h2>
            <p>WE GUARANTEE THE CONSISTENT QUALITY OF OUR WINES BY SELECTING THE MOST SUITABLE TERROIR, RESTRICTING YIELDS AND USING THE MOST ADVANCED WINEMAKING TECHNOLOGY. OUR WORK HAS BEEN VERIFIED BY GOLD MEDALS AND AWARDS ACHIEVED IN COMPETITIONS IN HUNGARY.</p>
          </header>
        </article>

        <section id="cta" className="wrapper white-background">
          <div className="inner">
            <section>
              <div className="box alt">
                <div className="row gtr-50 gtr-uniform">
                  <div className="col-12">
                    <span className="image fit gallery-thumbnail" >
                      <LightgalleryItem src={img1}><StaticImage  src="../../assets/images/gallery/1.jpg" alt="" /></LightgalleryItem>
                    </span>
                  </div>
                  
                  <div className="col-4">
                    <span className="image fit gallery-thumbnail">
                      <LightgalleryItem src={img2}><StaticImage   src="../../assets/images/gallery/2.jpg" alt="" /></LightgalleryItem>
                      </span>
                  </div>
                  
                  <div className="col-4">
                    <span className="image fit gallery-thumbnail">
                      <LightgalleryItem src={img3}><StaticImage   src="../../assets/images/gallery/3.jpg" alt="" /></LightgalleryItem>
                    </span>
                  </div>
                  <div className="col-4">
                    <span className="image fit gallery-thumbnail">
                      <LightgalleryItem src={img4}><StaticImage   src="../../assets/images/gallery/4.jpg" alt="" /></LightgalleryItem>
                    </span>
                  </div>
                  <div className="col-4"><span className="image fit gallery-thumbnail"><LightgalleryItem src={img5}><StaticImage  src="../../assets/images/gallery/5.jpg" alt="" /></LightgalleryItem></span></div>
                  <div className="col-4"><span className="image fit gallery-thumbnail"><LightgalleryItem src={img6}><StaticImage  src="../../assets/images/gallery/6.jpg" alt="" /></LightgalleryItem></span></div>
                  <div className="col-4"><span className="image fit gallery-thumbnail"><LightgalleryItem src={img7}><StaticImage  src="../../assets/images/gallery/7.jpg" alt="" /></LightgalleryItem></span></div>
                  <div className="col-12"><span className="image fit gallery-thumbnail"><LightgalleryItem src={img8}><StaticImage src="../../assets/images/gallery/8.jpg" alt="" /></LightgalleryItem></span></div>
                  <div className="col-4"><span className="image fit gallery-thumbnail"><LightgalleryItem src={img9}><StaticImage  src="../../assets/images/gallery/9.jpg" alt="" /></LightgalleryItem></span></div>
                  <div className="col-4"><span className="image fit gallery-thumbnail"><LightgalleryItem src={img10}><StaticImage src="../../assets/images/gallery/10.jpg" alt="" /></LightgalleryItem></span></div>
                  <div className="col-4"><span className="image fit gallery-thumbnail"><LightgalleryItem src={img11}><StaticImage src="../../assets/images/gallery/11.jpg" alt="" /></LightgalleryItem></span></div>
                  <div className="col-4"><span className="image fit gallery-thumbnail"><LightgalleryItem src={img12}><StaticImage src="../../assets/images/gallery/12.jpg" alt="" /></LightgalleryItem></span></div>
                  <div className="col-4"><span className="image fit gallery-thumbnail"><LightgalleryItem src={img13}><StaticImage src="../../assets/images/gallery/13.jpg" alt="" /></LightgalleryItem></span></div>
                  <div className="col-4"><span className="image fit gallery-thumbnail"><LightgalleryItem src={img14}><StaticImage src="../../assets/images/gallery/14.jpg" alt="" /></LightgalleryItem></span></div>
                  <div className="col-12"><span className="image fit gallery-thumbnail"><LightgalleryItem src={img0}><StaticImage src="../../assets/images/gallery/0.jpg" alt="" /></LightgalleryItem></span></div>
                  <div className="col-4"><span className="image fit gallery-thumbnail"><LightgalleryItem src={img16}><StaticImage src="../../assets/images/gallery/16.jpg" alt="" /></LightgalleryItem></span></div>
                  <div className="col-4"><span className="image fit gallery-thumbnail"><LightgalleryItem src={img17}><StaticImage src="../../assets/images/gallery/17.jpg" alt="" /></LightgalleryItem></span></div>
                  <div className="col-4"><span className="image fit gallery-thumbnail"><LightgalleryItem src={img18}><StaticImage src="../../assets/images/gallery/18.jpg" alt="" /></LightgalleryItem></span></div>
                  <div className="col-4"><span className="image fit gallery-thumbnail"><LightgalleryItem src={img19}><StaticImage src="../../assets/images/gallery/19.jpg" alt="" /></LightgalleryItem></span></div>
                  <div className="col-4"><span className="image fit gallery-thumbnail"><LightgalleryItem src={img20}><StaticImage src="../../assets/images/gallery/20.jpg" alt="" /></LightgalleryItem></span></div>
                  <div className="col-4"><span className="image fit gallery-thumbnail"><LightgalleryItem src={img21}><StaticImage src="../../assets/images/gallery/21.jpg" alt="" /></LightgalleryItem></span></div>
                </div>
              </div>
            </section>
          </div>
        </section>
      </Layout>
    </>
  )
}

export default IndexPage;
